import { IdleQueue } from "idlize/IdleQueue.mjs";
import loadScript from "load-script2";
import mobile from "is-mobile";
import autopilot from "modules/third-party-scripts/auto-pilot";

const queue = new IdleQueue();



  const jQueryUrl = "/public_site_assets/jquery-3.4.1-e200ee796ef24add7054280d843a80de75392557bb4248241e870fac0914c0c1.js";
  queue.pushTask(
    () => {
      loadScript(jQueryUrl).then(
        () => {
          queue.pushTask(() => {
            autopilot("5a452228fc074f999352c48f55572f4d5c5b3e5ab828471db98ca6e5dfff8880", "Y5INNEQi4sO7xZnZcmFuZ2VtZXNhbmRib3g");
          });
        },
        () => {
          Sentry.captureMessage("jQuery did not load from the CDN", { fingerprint: ["{{ default }}", "jQueryLoadFailed"] });
        }
      )
    }
  )

